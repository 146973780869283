import React, { useEffect, useRef } from 'react';
import './App.css';

function App() {
  const paragraphsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    paragraphsRef.current.forEach((paragraph) => {
      observer.observe(paragraph);
    });

    return () => {
      paragraphsRef.current.forEach((paragraph) => {
        observer.unobserve(paragraph);
      });
    };
  }, []);

  const paragraphs = [
    '10 Jesus replied, “If you only knew the gift God has for you and who you are speaking to, you would ask me, and I would give you living water.”',
    '11 “But sir, you don’t have a rope or a bucket,” she said, “and this well is very deep. Where would you get this living water? 12 And besides, do you think you’re greater than our ancestor Jacob, who gave us this well? How can you offer better water than he and his sons and his animals enjoyed?”',
    '13 Jesus replied, “Anyone who drinks this water will soon become thirsty again. 14 But those who drink the water I give will never be thirsty again. It becomes a fresh, bubbling spring within them, giving them eternal life.”',
    '15 “Please, sir,” the woman said, “give me this water! Then I’ll never be thirsty again, and I won’t have to come here to get water.”'
  ];

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="title">John 4 | NLT</div>
        {paragraphs.map((text, index) => (
          <p
            key={index}
            ref={(el) => (paragraphsRef.current[index] = el)}
            className="text"
          >
            {text}
          </p>
        ))}
      </div>
    </div>
  );
}

export default App;
